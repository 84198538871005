import Swiper from "swiper/swiper-bundle.mjs";

const progressBar = document.querySelector(".autoplay-progress-bar");

const autoplayer = (swiper) => {
  const video = document.querySelector(".swiper-slide video");
  const currentVideo = document.querySelector(".swiper-slide-active video");

  if (video) {
    video.pause();
    video.currentTime = 0;
  }

  if (currentVideo) {
    currentVideo.play();
    swiper.autoplay.stop();

    const vidTimer = setInterval(function () {
      const percent =
        Math.round(100 - (video.currentTime / video.duration) * 100) + "%";
      progressBar.style.setProperty("width", percent);
    }, 100);

    currentVideo.addEventListener(
      "ended",
      () => {
        clearInterval(vidTimer);
        swiper.slideNext();
      },
      false,
    );

    swiper.on("slideChangeTransitionEnd", function () {
      clearInterval(vidTimer);
      swiper.autoplay.start();
    });
  }
};

const heroSlideshow = new Swiper(".hero-slideshow", {
  loop: true,
  slidesPerView: 1,
  effect: "fade",
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".pagination",
    type: "fraction",
  },
  navigation: {
    nextEl: ".button-next",
    prevEl: ".button-prev",
  },
  on: {
    autoplayTimeLeft(s, time, progress) {
      const percent = Math.round(progress * 100) + "%";
      progressBar.style.setProperty("width", percent);
    },
  },
});

heroSlideshow.on("slideChangeTransitionEnd", function () {
  autoplayer(heroSlideshow);
});

const horizontalScroll = new Swiper(".horizontal-scroll", {
  slidesPerView: "auto",
  spaceBetween: 16,
  slidesPerView: 1,
  scrollbar: {
    el: ".swiper-scrollbar",
  },
  navigation: {
    nextEl: ".button-next",
    prevEl: ".button-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1080: {
      slidesPerView: 4,
    },
  },
});

const programmeHorizontalScroll = new Swiper(".programme-horizontal-scroll", {
  slidesPerView: "auto",
  spaceBetween: 16,
  slidesPerView: 1,
  scrollbar: {
    el: ".swiper-scrollbar",
  },
  navigation: {
    nextEl: ".button-next",
    prevEl: ".button-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
    1080: {
      slidesPerView: 3,
    },
  },
});

const latestHorizontalScroll = new Swiper(".latest-horizontal-scroll", {
  slidesPerView: "auto",
  spaceBetween: 16,
  slidesPerView: 1,
  scrollbar: {
    el: ".swiper-scrollbar",
  },
  navigation: {
    nextEl: ".button-next",
    prevEl: ".button-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
  },
});

export {
  heroSlideshow,
  horizontalScroll,
  programmeHorizontalScroll,
  latestHorizontalScroll,
};
