let map;

// Build map
async function initMap(target) {
  const lat = target.dataset.lat;
  const lng = target.dataset.lng;

  const zoom = parseFloat(target.dataset.zoom);
  const position = { lat: parseFloat(lat), lng: parseFloat(lng) };

  // Request needed libraries.
  const { Map } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  // Marker SVG
  const parser = new DOMParser();
  const pinSvgString =
    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 30 40"><path d="M30 15c0 11.12-15 25-15 25S0 26.12 0 15C0 6.72 6.72 0 15 0s15 6.72 15 15Z" fill="#cc0018"/><path d="M12.13 8.54v9.26c0 1.37.03 2.25.08 2.65.05.4.2.67.46.83.26.16.8.24 1.62.24 1.21 0 2.2-.16 2.97-.5.78-.33 1.43-.98 1.95-1.95s.78-2.21.78-3.71c0-1.17-.15-2.22-.45-3.14-.3-.92-.74-1.67-1.3-2.24-.57-.58-1.19-.97-1.88-1.18-.69-.21-1.57-.31-2.65-.31-.52 0-1.04.02-1.57.05m-4.28-.28 2.28-.11c1.9-.1 3.18-.14 3.84-.14 2.06 0 3.66.31 4.81.94 1.15.63 2.01 1.48 2.57 2.55.56 1.07.84 2.31.84 3.71 0 1.01-.15 1.94-.46 2.78-.31.84-.73 1.54-1.26 2.09-.54.56-1.16.98-1.86 1.27-.7.29-1.37.47-2.01.54-.63.07-1.53.11-2.68.11H7.79v-.47h.57c.59 0 1-.07 1.23-.22.23-.15.37-.38.44-.7.06-.32.1-1.05.1-2.21v-6.68c0-1.1-.03-1.82-.1-2.14-.07-.32-.2-.54-.41-.66-.21-.12-.66-.18-1.36-.18h-.41v-.47Z" fill="#fff"/></svg>';
  const pinSvg = parser.parseFromString(
    pinSvgString,
    "image/svg+xml",
  ).documentElement;

  // The map
  map = new Map(target, {
    center: position,
    mapId: "ditchley_foundation",
    zoom: zoom,
  });

  // The markers
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    position: position,
    content: pinSvg,
    title: "Ditchley Foundation",
  });
}

// Look for all map containers
const buildMaps = window.addEventListener(
  "load",
  (event) => {
    document.querySelectorAll(".google-map").forEach(function (i) {
      if (i) {
        initMap(i);
      }
    });
  },
  false,
);

export { buildMaps };
