const videoPlayer = document.querySelectorAll("video").forEach(function (i) {
  const video = i;
  const bgGrad = video.parentNode.querySelector(".gradient");

  const controls = video.nextElementSibling;
  const playpause = controls.querySelector("#playpause");
  const mute = controls.querySelector("#mute");
  const fs = controls.querySelector("#fs");

  video.addEventListener(
    "play",
    () => {
      changeButtonState("playpause");
    },
    false,
  );

  video.addEventListener(
    "pause",
    () => {
      changeButtonState("playpause");
    },
    false,
  );

  playpause.addEventListener("click", (e) => {
    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  });

  mute.addEventListener("click", (e) => {
    video.muted = !video.muted;
    changeButtonState("mute");
  });

  fs.addEventListener("click", (e) => {
    video.controls = true;
    bgGrad.remove();
    controls.classList.add("hidden");
  });

  function changeButtonState(type) {
    if (type === "playpause") {
      // Play/Pause button
      if (video.paused || video.ended) {
        playpause.setAttribute("data-state", "play");
      } else {
        playpause.setAttribute("data-state", "pause");
      }
    } else if (type === "mute") {
      // Mute button
      mute.setAttribute("data-state", video.muted ? "unmute" : "mute");
    }
  }
});

export { videoPlayer };
