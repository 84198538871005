import * as nav from "./modules/navigation.js";
import videoPlayer from "./modules/player.js";
import * as carousel from "./modules/carousels.js";
import animateOnScroll from "./modules/observer.js";
import buildMaps from "./modules/map.js";
import { openModal, closeModal } from "./modules/modal.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
    headerFormat();
  },
  false,
);

// Sticky header
const headerFormat = () => {
  const container = document.querySelector("header .container");
  const logo = document.querySelector("#logo svg");
  const nav = document.querySelector("#main-navigation");

  if (window.scrollY > 40) {
    container.classList.add("!py-4");
    logo.classList.add("!h-8");
    nav.classList.add("pt-16");
  } else {
    container.classList.remove("!py-4");
    logo.classList.remove("!h-8");
    nav.classList.remove("pt-16");
  }
};

window.addEventListener("scroll", headerFormat, false);

// Timeline indicator
const timeline = document.querySelector(".timeline");

if (timeline) {
  window.onscroll = function () {
    updateProgress();
  };

  updateProgress();
}

function updateProgress() {
  const timeline = document.querySelector(".timeline").getBoundingClientRect();

  if (timeline.top < window.innerHeight && timeline.bottom > 0) {
    const winScroll = window.innerHeight - timeline.top;
    const height = window.innerHeight + timeline.height;

    const scrolled = (winScroll / height) * 100;

    document.querySelector(".timeline-progress").style.height = scrolled + "%";

    document.querySelectorAll(".segment").forEach(function (i) {
      const segmentOffset = i.offsetParent.offsetTop;
      const innerOffset = i.querySelector("h4").offsetTop;

      const offset = ((segmentOffset + innerOffset) / timeline.height) * 100;

      if (scrolled > offset) {
        i.classList.add("animated");
      } else {
        i.classList.remove("animated");
      }
    });
  }
}

// UI
$(".accordion-toggle").click(function () {
  $(this).find("svg").toggleClass("hidden");
  $(this).next().slideToggle();
});

$("body").on("click", ".open-filter", function () {
  $(".event-filter").removeClass("hidden");
  openModal();
});

$("body").on("click", ".close-filter", function () {
  $(".event-filter").addClass("hidden");
  closeModal();
});

$("body").on("click", ".filter-toggle", function () {
  if ($(window).width() > 640) {
    $(".filter-group").not($(this).next()).hide();
  }

  $(this).next().slideToggle();
});
