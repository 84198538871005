import { openModal, closeModal } from "./modal.js";

const menu = document.querySelector("#main-navigation");
const hamburger = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const closeIcon = document.querySelector(".closeIcon");

// Toggle nav
hamburger.addEventListener("click", function () {
  menu.classList.contains("hidden") ? openNav() : closeNav();
});

const searchToggle = document.querySelector(".search-toggle");
const search = document.querySelector(".search-container");

searchToggle.addEventListener("click", function () {
  if (search.classList.contains("hidden")) {
    search.classList.remove("hidden");
    document.querySelector(".search-container input").focus();
  } else {
    search.classList.add("hidden");
  }
});

$(".toggle-subnav").click(function () {
  $(".subnav").addClass("hidden");
  $(this).siblings(".subnav").removeClass("hidden");
});

$(".close-subnav").click(function () {
  $(this).parent().parent().addClass("hidden");
});

// Functions
const openNav = () => {
  menu.classList.remove("hidden");
  menuIcon.classList.add("hidden");
  closeIcon.classList.remove("hidden");
  $(".search-container").addClass("hidden");
  openModal();
};

const closeNav = () => {
  menu.classList.add("hidden");
  menuIcon.classList.remove("hidden");
  closeIcon.classList.add("hidden");
  $(".subnav").addClass("hidden");
  closeModal();
};

export { openNav, closeNav };
